import { IRegisterPayload, IHubspotFormAPIPayload } from '../types/interfaces';
import zipcodes from 'zipcodes';

export const hubspotFormAPIData = (formAPIData: IRegisterPayload): IHubspotFormAPIPayload => {
  const hubspotFormAPIPayload = {
    fields: [
      {
        objectTypeId: '0-1',
        name: 'email',
        value: formAPIData?.email,
      },
      {
        objectTypeId: '0-1',
        name: 'firstname',
        value: formAPIData?.firstName,
      },
      {
        objectTypeId: '0-1',
        name: 'lastname',
        value: formAPIData?.lastName,
      },
      {
        objectTypeId: '0-1',
        name: 'mobilephone',
        value: formAPIData?.phoneNumber,
      },
      {
        objectTypeId: '0-1',
        name: 'zip_code',
        value: formAPIData?.zipCode,
      },
      {
        objectTypeId: '0-1',
        name: 'address',
        value: 'No address field available atm',
      },
      {
        objectTypeId: '0-1',
        name: 'city',
        value: zipcodes.lookup(formAPIData?.zipCode)?.city || 'No City Found',
      },
      {
        objectTypeId: '0-1',
        name: 'state',
        value: zipcodes.lookup(formAPIData?.zipCode)?.state || 'No State Found',
      },
    ],
    context: {
      pageUri: window.location.href,
      pageName: 'Signup page',
    },
  };

  return hubspotFormAPIPayload;
};
