import { AlertProps } from '@mui/joy';
import { SxProps } from '@mui/material';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { AxiosError } from 'axios';

export enum UserRole {
  // eslint-disable-next-line no-unused-vars
  Admin = 'Admin',
  // eslint-disable-next-line no-unused-vars
  Worker = 'Worker',
  // eslint-disable-next-line no-unused-vars
  Provider = 'Provider',
}

export enum ToastMode {
  // eslint-disable-next-line no-unused-vars
  WellDone = 'WellDone',
  // eslint-disable-next-line no-unused-vars
  Success = 'Success',
  // eslint-disable-next-line no-unused-vars
  Error = 'Error',
}

export enum GigStatus {
  // eslint-disable-next-line no-unused-vars
  Unassigned = 'Unassigned',
  // eslint-disable-next-line no-unused-vars
  Assigned = 'Assigned',
  // eslint-disable-next-line no-unused-vars
  Completed = 'Completed',
  // eslint-disable-next-line no-unused-vars
  Cancelled = 'Cancelled',
}

export enum GigApplicationStatus {
  // eslint-disable-next-line no-unused-vars
  Unapplied = 'Unapplied',
  // eslint-disable-next-line no-unused-vars
  Pending = 'Pending',
  // eslint-disable-next-line no-unused-vars
  Accepted = 'Accepted',
  // eslint-disable-next-line no-unused-vars
  Declined = 'Declined',
}

export enum GigPreviewItemContext {
  // eslint-disable-next-line no-unused-vars
  GigDetails = 'GigDetails',
  // eslint-disable-next-line no-unused-vars
  FindGigs = 'FindGigs',
  // eslint-disable-next-line no-unused-vars
  Feed = 'Feed',
  // eslint-disable-next-line no-unused-vars
  MyGigs = 'MyGigs',
}

export enum GigDetailsFooterBlockModesEnum {
  // eslint-disable-next-line no-unused-vars
  Declined = 'Declined',
  // eslint-disable-next-line no-unused-vars
  Completed = 'Completed',
  // eslint-disable-next-line no-unused-vars
  Cancelled = 'Cancelled',
}

export interface GigDetailsDates {
  id: string;
  rate: string;
  ISO8601DateTimeStart: string;
  ISO8601DateTimeEnd: string;
  isSelected: boolean;
}

export interface MenuItem {
  label: string;
  onClick: () => void;
  Icon: JSX.Element;
}

export interface JwtTokenPayload {
  exp: number;
  iat: number;

  user: {
    createdAt: ISO8601String;
    firstName: User['firstName'];
    id: User['id'];
    lastName: User['lastName'];
    role: User['role'];
  };
}

export enum ActionableSearchInputSubmitMode {
  // eslint-disable-next-line no-unused-vars
  AutoCleanOnSubmit = 'AUTO_CLEAN_ON_SUBMIT',
  // eslint-disable-next-line no-unused-vars
  ManualCleanOnSubmit = 'MANUAL_CLEAN_ON_SUBMIT',
}

export enum FilterPanel {
  // eslint-disable-next-line no-unused-vars
  Location = 'Location',
  // eslint-disable-next-line no-unused-vars
  JobType = 'Job Type',
  // eslint-disable-next-line no-unused-vars
  PayRange = 'Pay Range',
}

export enum AutoCompleteOptionsPickerMode {
  // eslint-disable-next-line no-unused-vars
  Top = 'TOP',
  // eslint-disable-next-line no-unused-vars
  Bottom = 'BOTTOM',
}
export interface FormValues {
  phoneNumber: string;
}
export interface User {
  token: string;
  id: string;
  email: string;
  firstName: string;
  middleName: string;
  isOnBoardingComplete: boolean;
  areJobPreferencesAdded: boolean;
  isPaymentMethodAdded: boolean;
  isBasicGigTrainingCompleted: boolean;
  bio: string;
  lastName: string;
  phoneNumber: string;
  role: UserRole;
  createdAt: string;
  rating: number;
  skills?: SkillsEntity[] | null;
  jobs: number;
  zipCode: string;
  profileURL: string;
  hours: string;
}

export interface GigPayRangeFilterBodyProps {
  filterName: string;
  filterContent: PayRange;
  containerStyle?: SxProps | undefined;
  setFilterContent: React.Dispatch<React.SetStateAction<PayRange>>;
  onReset: () => void;
  onBack: () => void;
}

export interface GigJobTypeFilterBodyProps {
  filterName: string;
  filterContent: JobType;
  containerStyle?: SxProps | undefined;
  setFilterContent: React.Dispatch<React.SetStateAction<JobType>>;
  onReset: () => void;
  onBack: () => void;
}

export interface HourlyPayRange {
  min: number;
  max: number;
}

export interface Location {
  city: string | null;
  zipCode: string | null;
  mode: 'city' | 'zipcode';
}

export interface JobType {
  fullTime: boolean;
  partTime: boolean;
}

export interface SubmitButtonProps {
  label: string;
  isLoading: boolean;
  onClick: () => void;
  customButtonStyle?: React.CSSProperties | undefined;
}

export interface GigFilterBodyHeaderProps {
  filterName: string;
  onBack: () => void;
  onReset: () => void;
  containerStyle?: SxProps | undefined;
}

export interface GigLocationFilterBodyProps {
  onBack: () => void;
  onReset: () => void;
  filterName: string;
  filterContent: LocationFilter;
  setFilterContent: React.Dispatch<SetStateAction<LocationFilter>>;
  containerStyle?: SxProps | undefined;
}
export interface SkillsEntity {
  id: string;
  skill: string;
  status: string;
}

export type ServerError = AxiosError<{
  code: string;
  errorType: string;
  message: string;
  method: string;
  path: string;
}>;

export type ISO8601String = string;

export interface AuthContextType {
  user: User;
  setUser: Function;
  logout: () => void;
  refetch: Function;
  refetchWithoutLoadingUI: Function;
}

export interface RadioControllerProps {
  name: string;
  children: ReactNode;
  sx?: SxProps | undefined;
}

export interface GigFilterListItemProps {
  onClick: () => void;
  filterName: string;
  filterContent: string;
  containerStyle?: SxProps | undefined;
}

export interface IRegisterPayload {
  email: string;
  firstName: string;
  middleName: string;
  lastName: string;
  termsAndConditions: boolean;
  phoneNumber: string;
  password: string;
  zipCode: string;
  code: string;
}

export interface InputFieldProps {
  label: string;
  type?: string;
  name: string;
  autoCapitalize?: 'on' | 'off';
}

export interface CustomAlertProps extends AlertProps {
  title?: string;
  message: string;
}

export interface UnlockMoreGigsEntryProps {
  onClick: () => void;
}

export interface GigPreviewItemProps {
  id: string;
  imageUrl?: string;
  name: string;
  ISO8601DateTimeStart: string;
  ISO8601DateTimeEnd: string;
  location: string;
  distance?: number;
  hourlyRate: number;
  estimatedPrice: number;
  clientRating?: number;
  bookmarked: boolean;
  status: GigStatus;
  city: string;
  zipCode?: string;
  isFulltime: boolean;
  bonus?: number;
  isAnonymous?: boolean;
  isExternal?: boolean;
  componentContext: GigPreviewItemContext;
  applicationStatus: GigApplicationStatus;
  handleClick: () => void;
}

export interface StepProps {
  onClick: () => void;
  completed: boolean;
  title: string;
  headline: string;
  pictures?: string[];
  description?: string;
  timeToCompletionInMinutes?: number;
}

export interface LifetimeStatsProps {
  rating: number;
  jobs: number;
  hours: number;
  stars: number;
}

export interface ProfileAboutProps {
  about: string;
}

export interface ProfileRateAndSkillsProps {
  hourlyRate: number;
  skills: {
    name: string;
    isVerified: boolean;
  }[];
}

export interface HourlyRateLabelProps {
  hourlyRate: number;
}

export interface Gig {
  id: string;
  title: string;
  ISO8601DateTimeStart: string;
  ISO8601DateTimeEnd: string;
  rating: number;
  earned: number;
  charges: number;
  hours: number;
  review: string;
  status: GigStatus;
}

export interface GigsHistoryProps {
  gigs: Gig[];
}

export interface GigsHistoryItemProps {
  id: string;
  title: string;
  rating: number;
  ISO8601DateTimeStart: string;
  ISO8601DateTimeEnd: string;
  review: string;
  earned: number;
  charges: number;
  hours: number;
}

export interface ProfilePictureAndDetailsProps {
  pictureUri: string;
  fullName: string;
  location: string;
}

export interface EditProfilePictureProps {
  pictureUri: string;
  onEditClick: () => void;
}

export interface DeletableSkillTagProps {
  children: string;
  onDeleteClick: (children: string) => void;
}

export interface ActionableSearchInputProps {
  onClick: (text: string) => void;
  buttonLabel: string;
  submitMode: ActionableSearchInputSubmitMode;
  placeholder: string;
  validateOnSubmit: (text: string) => string | null;
  autoCompleteOptions?: string[];
  autoCompleteSelectedOptions?: string[];
  autoCompleteOptionsPickerMode?: AutoCompleteOptionsPickerMode;
}

export interface ProtectedLayoutProps {
  children: React.ReactNode;
}

export interface PublicRouteProps {
  children: React.ReactNode;
}

export interface ConnectorProps {
  steps: string[];
  activeStep: number;
}

export interface ExpandButtonProps {
  onClick: () => void;
  isParentExpanded: boolean;
}

export interface StatBlockProps {
  Icon: JSX.Element;
  value: number;
  label: string;
}

export interface PhoneInputProps {
  name: string;
}

export interface SkillTagProps {
  children: string;
  isVerified: boolean;
}

export interface TabSwitcherProps {
  tabs: string[];
  activeTabIndex: number;
  onTabClick: (index: number) => void;
}

export interface SearchGigsBySkillEntryProps {
  onClick: () => void;
  label: string;
}

export interface MyEarningsProps {
  myEarningsToday: {
    value: string;
    activeHours?: number;
  };
  myEarningsWeekly: {
    value: string;
    activeHours?: number;
  };
  myEarningsMonthly: {
    value: string;
    activeHours?: number;
  };
}

export interface MyTransactionDetailsProps {
  id: string;
  userId: string;
  gigId: string;
  transactionId: string;
  dateAndTime: string;
  amount: number;
  gigName: string;
  referenceNumber: string;
  downloadInvoiceURL: string;
}

export interface DateRangeButtonProps {
  onClick: () => void;
}

export interface AddPaymentMethodButtonProps {
  onClick: () => void;
}

export interface PaymentMethodProps {
  isPrefered: boolean;
  paymentType: string;
  cashAppId?: string;
  status: string;
  accountNo?: string;
  swiftCode?: string;
  bankName?: string;
  onEditClick: () => void;
  onDeleteClick: () => void;
  onTogglePrefered: () => void;
}

export interface UploadFileAreaProps {
  onFileUpload: () => void;
}

export interface SummaryGigEarningsProps {
  timeSpent: string;
  ISO8601DateTimeCheckout: string;
  onEditClick: () => void;
}

export interface UploadButtonProps {
  name: string;
  buttonText: string;
  icon?: JSX.Element;
  variant?: 'circle' | 'rectangle';
  containerStyles?: SxProps | undefined;
  formKey: string;
}

// eslint-disable-next-line no-unused-vars
export enum SkillType {
  // eslint-disable-next-line no-unused-vars
  Technical = 'Technical',
  // eslint-disable-next-line no-unused-vars
  Soft = 'Soft',
  // eslint-disable-next-line no-unused-vars
  General = 'General',
}

export interface Skill {
  id: string;
  name: string;
  type: SkillType;
  description: string;
  trainingRequirement: string;
  createdAt: string;
  updatedAt: string;
}

export interface City {
  id: string;
  name: string;
}

export interface PayRange {
  min: number;
  max: number;
  mode: 'hourly' | 'yearly';
}

export interface LocationFilter {
  zipCode: string | null;
  city: string | null;
  mode: 'zipcode' | 'city';
}

export interface JobType {
  partTime: boolean;
  fullTime: boolean;
}

export interface LeaderboardPodiumProps {
  firstPlaceName: string;
  firstPlacePictureUri: string;
  firstPlaceScore: string;

  secondPlaceName: string;
  secondPlacePictureUri: string;
  secondPlaceScore: string;

  thirdPlaceName: string;
  thirdPlacePictureUri: string;
  thirdPlaceScore: string;
}

export interface LeaderboardPersonProps {
  name: string;
  place: number;
  rating: string;
  jobs: number;
  pictureUri: string;
}

export interface GigDetailsDatesAndTimesProps {
  estDays: number;
  estMoney: string;
  dates: { [key: string]: GigDetailsDates };
  setDates: Dispatch<SetStateAction<{ [key: string]: GigDetailsDates }>>;
}

export interface GigDetailsDateTimeOptionProps {
  ISO8601DateTimeStart: string;
  ISO8601DateTimeEnd: string;
  isSelected: boolean;
  rate: string;
  id: string;
  toggleDate: ({ id }: { id: string }) => void;
}

export interface GigDetailsRequirementsProps {
  requirements: string;
}

export interface GigDetailsTravelTipsProps {
  travelTips: string;
}

export interface GigDetailsLocationProps {
  location: string;
}

export interface GigDetailsFooterBlockUnassignedGigProps {
  withdrawPolicyUrl: string;
  frontlineTermsOfUseUrl: string;
  biometricDataPolicyUrl: string;
  biometricDataConsentFormUrl: string;
  gigStatus: GigStatus;
  gigId: GigBaseItem['id'];
  setGig: React.Dispatch<React.SetStateAction<GigBaseItem | null>>;
}

export interface GigDetailsFooterBlockAppliedGigProps {
  gigId: GigBaseItem['id'];
  setGig: React.Dispatch<React.SetStateAction<GigBaseItem | null>>;
}

export interface GigDetailsFooterBlockAssignedGigProps {
  onReportClick: () => void;
  gigId: GigBaseItem['id'];
  setGig: React.Dispatch<React.SetStateAction<GigBaseItem | null>>;
}

export interface MenuProfilePictureProps {
  pictureUri: string;
  fullName: string;
  location: string;
  rating: number;
  onViewProfileClick: () => void;
  onEditProfileClick: () => void;
}

export interface MenuItemsBlockProps {
  items: MenuItem[];
}

export interface WebNavigationBarProps {
  navItems: DashboardTabItem[];
  currentTabLabel: DashboardTabLabel;
  isAnonymous?: boolean;
}

export interface MobileNavigationBarProps {
  navItems: DashboardTabItem[];
  isBottomNotchPresent: boolean;
  currentTabLabel: DashboardTabLabel;
  isAnonymous?: boolean;
}

export type DashboardTabLabel = 'Home' | 'Find Gigs' | 'My Gigs' | 'Payment' | 'Profile';

export interface DashboardTabItem {
  label: DashboardTabLabel;
  onClick: (value: DashboardTabLabel) => void;
  Icon: React.FC<React.SVGProps<SVGSVGElement>> | (({ color }: { color: string }) => JSX.Element);
}

export interface DashboardProfileProps {
  label: string;
}

export interface GigReport {
  afterWorkComment: string;
  afterWorkPicUrl: string;
  arrivalDateAndTime: string;
  beforeWorkComment: string;
  beforeWorkPicUrl: string;
  checkoutDateAndTime: string;
  createdAt: string;
  gigId: Gig['id'];
  location: string;
  updatedAt: string;
  userId: User['id'];
}

export interface GigBaseItem {
  id: string;
  applicationStatus: GigApplicationStatus;
  name: string;
  hourlyRate: number;
  featuredImage: string;
  city: string;
  zipCode?: string;
  isFulltime: boolean;
  isExternal?: boolean;
  reports?: GigReport[];
  startDateAndTime: string;
  endDateAndTime: string;
  estimatedPrice: number;
  description: string;
  location: string;
  skills: string[];
  status: GigStatus;
  userId: string;
  bookmarked: boolean;
  requirements: string;
  travelTips: string;
  createdAt: string;
  updatedAt: string;
}

export interface GigApplication {
  userId: User['id'];
  gigId: GigBaseItem['id'];
  status: GigApplicationStatus;
  user: {
    firstName: User['firstName'];
    lastName: User['lastName'];
    profileURL: User['profileURL'];
  };
}

export interface AdminGigBaseItem extends GigBaseItem {
  user: {
    firstName: User['firstName'];
    lastName: User['lastName'];
  };
  applications: GigApplication[];
}

export interface UnlockMoreGigsHeaderProps {
  goBack: () => void;
  backgroundColor: string;
  numberOfCompletedSteps: number;
}

export interface DeleteBookmarkPayload {
  gigId: string;
}

export interface DeleteSkillPayload {
  skillId: string;
}

export interface AddUserSkills {
  skills?: UserSkillsEntity[] | null;
}
export interface UserSkillsEntity {
  skillId: string;
  userId: string;
}

export interface AddBookmarkPayload {
  gigId: string;
}

export interface OnboardingStepSkillsProps {
  addSelectedSkill: (skillLabel: { skillLabel: Skill['name'] }) => void;
  skillsLabels: Skill['name'][];
  selectedSkillsLabels: Skill['name'][];
  removeSelectedSkill: (skillLabel: string) => void;
}

export interface ActiveGigsDateLabels {
  [key: string]: number;
}

export interface GenericHeaderProps {
  goBack: () => void;
  header: string;
}

export interface SummaryGigWorkPicsProps {
  pictureFieldFormName: string;
  commentFieldFormName: string;
  title: string;
}

export interface GigReportFormData extends FormData {
  gigId: string;
  location: string;
  arrivalDateAndTime: Date | null;
  checkoutDateAndTime: Date | null;
  beforeWorkPic: File | null;
  afterWorkPic: File | null;
  beforeWorkComment: string;
  afterWorkComment: string;
}

export interface CreateGigFormData extends FormData {
  name: string;
  location: string;
  travelTips: string;
  requirements: string;
  city: string;
  featuredImage: File | null;
  skills: string[];
  startDateAndTime: string;
  endDateAndTime: string;
  hourlyRate: number;
  estimatedPrice: number;
  description: string;
  isFulltime: boolean;
}

export interface EditGigFormData extends FormData {
  name: string;
  location: string;
  travelTips: string;
  requirements: string;
  city: string;
  zipCode: string;
  isFulltime: boolean;
  status: GigStatus;
  featuredImage: File | null;
  skills: string[];
  startDateAndTime: string;
  endDateAndTime: string;
  hourlyRate: number;
  estimatedPrice: number;
  description: string;
}

export interface IPaginator {
  total: number;
  lastPage: number;
  currentPage: number;
  perPage: number;
  prev?: number;
  next?: number;
}

export interface LoadMoreButtonProps {
  next: number | undefined;
  onClick: () => void;
  loading: boolean;
}
export interface ProfileMenuItemProps {
  label: string;
  href?: string;
  onClick: (value: string) => void;
  Icon: React.FC<React.SVGProps<SVGSVGElement>> | (({ color }: { color: string }) => JSX.Element);
}

export interface ProfileMenuProps {
  label: string;
}

export interface WebProfileNavigationBarProps {
  navItems: ProfileMenuItemProps[];
  currentTabLabel: string;
}

export interface MobileProfileNavigationBarProps {
  navItems: ProfileMenuItemProps[];
  currentTabLabel: string;
  label?: string;
  href?: string;
}

export type ProfileLifeStatsLabel = 'Rating' | 'Jobs' | 'Hours' | '5 Star';

export interface ResetPasswordRequestPayload {
  oldPassword: string;
  newPassword: string;
}

export interface GigDetailsFooterBlockLabelProps {
  currentMode: GigDetailsFooterBlockModesEnum;
}

export interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export interface MyTransaction {
  id: string;
  userId: string;
  gigId: string;
  transactionId: string;
  amount: number;
  referenceNumber: string;
  dateAndTime: string;
  createdAt: string;
  updatedAt: string;
  downloadInvoiceURL: string;
  gig: {
    name: GigBaseItem['name'];
  };
}

export interface PaymentMethod {
  id: string;
  cashAppId: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
}

export interface IField {
  objectTypeId: string;
  name: string;
  value: string;
}

export interface IContext {
  pageUri: string;
  pageName: string;
}

export interface IHubspotFormAPIPayload {
  fields: IField[];
  context: IContext;
}
